import { graphql } from 'gatsby'
import React from 'react'
import HeroSlider from '../components/Home/HeroSlider/HeroSlider'
import GridProducts from '../components/Home/GridProductsHome/GridProducts'
import Seo from '../components/Shared/Seo/Seo'
import { useLocale, useSchema } from '../hooks'
import * as styles from '../styles/local.module.scss'
const Home = ({
    location,
    pageContext,
    data: {
        contentfulPageHome: {
            slug,
            heroComponent,
            sections,
            seoDescription,
            seoTitle
        },
        allContentfulPageHome: {
            nodes: allLinks
        }
    }
}) => {
    const locale = useLocale()
    return (
        <div>
            <h1 style={{ display: 'none' }}>{seoTitle}</h1>
            <Seo
                title={seoTitle}
                lang={locale}
                description={seoDescription}
                schemas={[
                    useSchema("home", {})
                ]}
                slug={slug}
                hrefLangs={allLinks}
                home={true}
            />
            <div className={styles.container}>
                {heroComponent && <HeroSlider array={heroComponent} />}
                {
                    sections?.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulComponentGridSection":
                                return (
                                    <GridProducts data={section} key={index} />
                                )
                        }
                    })
                }
            </div>
        </div>
    )
}

export const HomeQuery = graphql`
    query homePage($id: String!, $contentful_id: String!)  {
        contentfulPageHome(id: { eq: $id }) {
                slug
                seoTitle
                seoDescription
                heroComponent {
                    title
                    heroImage {
                        title
                        gatsbyImageData(quality: 75)
                        height
                    }
                    ctaButton {
                        title
                        slug
                    }
                }
                sections {
                    __typename
                    products {
                        title: thumbnailTitle
                        slug
                        heroImage {
                            title
                            gatsbyImageData
                        }
                    }
                }
        }
        allContentfulPageHome(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}}){
            nodes {
                slug
                node_locale
            }
        }
    }
`

export default Home