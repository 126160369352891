import React from 'react'
import * as styles from './grid-products.module.scss'

import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { useLocalePath } from '../../../hooks'

const GridProducts = ({ data }) => {
    const {
        products
    } = data
    // const product = products[0]
    return (
        <div>
            <div className={styles.productGrid}>
                {
                    products.map((product, index) => {
                        return (
                            <article key={index}>
                                <div className={styles.productContainer}>
                                    <Link to={useLocalePath(product.slug)}>
                                        <h3 className={styles.productTitle}>{product.title}</h3>
                                    </Link>
                                    <figure className={styles.productImage}>
                                        <Link to={useLocalePath(product.slug)}>
                                            <GatsbyImage
                                                image={product.heroImage.gatsbyImageData}
                                                alt={`Red Rose ${product.title}`}
                                            />
                                        </Link>
                                    </figure>
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default GridProducts