import React, { useRef, useEffect, useState } from 'react'
import * as styles from './hero-slider.module.scss';
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import gsap from 'gsap';
import { useLocalePath, useActive, useSiteQuery } from '../../../hooks';

import { Pagination, Autoplay, EffectFade } from "swiper"

import { SwiperSlide, Swiper } from "swiper/react"

import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"


const HeroSlider = ({
    array,
    item
}: {
    array?: any,
    item?: any
}) => {
    // const [active, setActive] = useState(false)
    const active = useActive()
    const { learnMoreTranslation } = useSiteQuery()
    useEffect(() => {
        let pointers = document.getElementsByClassName('swiper-pagination-bullet')
        for (let item in pointers) {
            pointers[item]?.classList?.add('pointers')
        }
    }, [])

    // setActive(useActive())

    useEffect(() => {
        if (active) {
            mySwiper.current.swiper.autoplay.start()
        }
    }, [active])

    const mySwiper = useRef()

    return (
        <section className={styles.heroContainer}>
            <div className={styles.imageContainer}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay, EffectFade]}
                    effect={"slide"}
                    autoplay={false}
                    loop={true}
                    className={`mySwiper ${styles.swiperContainer}`}
                    allowTouchMove={true}
                    ref={mySwiper}
                >
                    {
                        array.map(({ heroImage, title, ctaButton }, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className={styles.container}>
                                        <Link to={useLocalePath(ctaButton.slug)} className={`hero-image`}>
                                            <GatsbyImage
                                                image={heroImage.gatsbyImageData}
                                                className={styles.image}
                                                alt={`Red Rose - ${title}`}
                                                loading="eager"
                                            />
                                        </Link>
                                        <div className={`${styles.imageContent}`}>
                                            <div className='hero-content'>
                                                <h2 className={styles.heroTitle}>{title}</h2>
                                                {/* <Link to={`/${ctaButton.slug}`} className={styles.cta}>{ctaButton.title}</Link> */}
                                                <Link to={useLocalePath(ctaButton.slug)} className={styles.cta}>{learnMoreTranslation}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default HeroSlider