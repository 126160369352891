// extracted by mini-css-extract-plugin
export var active = "hero-slider-module--active--cvU0O";
export var container = "hero-slider-module--container--ZqlbU";
export var cta = "hero-slider-module--cta--b0NNV";
export var heroContainer = "hero-slider-module--hero-container--mv4jo";
export var heroTitle = "hero-slider-module--hero-title--7f-vC";
export var image = "hero-slider-module--image--tWxQb";
export var imageContainer = "hero-slider-module--image-container--d7eJt";
export var imageContent = "hero-slider-module--image-content--sgEKt";
export var pointerContainer = "hero-slider-module--pointer-container--zY0DR";
export var pointers = "hero-slider-module--pointers--IjStZ";
export var swiperContainer = "hero-slider-module--swiper-container--OCXm3";